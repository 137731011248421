@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    /*font-family: "GT Walsheim Condensed Bold", sans-serif;*/
    font-family: "Montserrat", sans-serif;
}

::selection {
    background-color: #a689c1;
    color: white;
}

.text-shadow {
    text-shadow: 0 0 20px rgba(233, 201, 252, 0.4);
}

.hero-image {
    height: calc(100% + 110px);
    width: 100%;

    top: -110px;

    background-image: url("../../public//background2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    position: absolute;
}

@font-face {
    font-family: "GT Walsheim Condensed Bold";
    src: url("../../public/gt-walsheim-condensed-bold.woff");
}

@font-face {
    font-family: "Raleway";
    src: url("../../public/raleway-medium.woff");
}

@font-face {
    font-family: "Raleway Semibold";
    src: url("../../public/raleway-semibold.woff");
}